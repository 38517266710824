<template>
	<div class="filter-card">
		<v-menu
			left
			offset-y
			:nudge-width="340"
			max-width="340"
			v-model="filtercard"
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					color="blue darken-4 text-white"
					depressed
					tile
					class="my-auto ms-2 filter_menu"
				>
					<v-icon class="mr-1">mdi-filter-variant</v-icon>
					Filter
				</v-btn>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<div class="text-h6 text-center title_card">Filter</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="filtercard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<div class="" style="max-height: 80vh; overflow: scroll">
				<v-list class="px-2">
					<div>
						<label for="">Keyword</label>
						<TextInput
							class="mt-0 rounded"
							hide-details
							outlined
							v-model="keyword_field"
							placeholder="Enter Keyword..."
						>
						</TextInput>
						<span style="font-size: 12px; color: #808080 !important"
							>Search cards, members, labels, and more.</span
						>
					</div>
				</v-list>
				<!-- members listing -->
				<v-list class="px-3">
					<span class="">members</span>
					<template v-for="(item, index) in boardMembers">
						<v-list-item
							class="px-0"
							:key="index"
							style="min-height: 34px !important; height: 34px; display: block"
						>
							<v-checkbox
								class="members-check mt-1 position-absolute"
								hide-details
								v-model="item.selectedMember"
							>
								<template #label v-if="item.title">
									<v-list-item-avatar size="25" :color="item.color" v-if="item.title">
										<span class="text-h6" v-if="item.text">{{ item.text }}</span>
										<v-icon size="20" v-else>{{ item.icon }}</v-icon>
									</v-list-item-avatar>
									{{ item.title }}</template
								>
								<template #append v-else>
									<v-autocomplete
										style="background-color: rgb(238, 231, 231); width: 100% !important; max-width: 100%"
										:items="membersListItem"
										hide-details
										outlined
										placeholder="Select Members"
										v-model="membersSelect"
										item-text="title"
										item-value="id"
										required
										class="mb-2 mt-1 rounded"
									>
										<template v-slot:selection="data">
											<span class="text-capitalize"> {{ data.item.id }} Select Members</span>
										</template>
										<template v-slot:item="data">
											<template>
												<v-list-item-content style="min-width: 300px !important">
													<v-checkbox
														v-model="data.item.members_Select"
														class="members-check mx-0 my-0"
														hide-details
													>
														<template #append>
															<v-list-item-avatar size="25" :color="data.item.color" class="mr-2 my-0">
																<span class="text-h6" v-if="data.item.text_Title">{{ data.item.text_Title }}</span>
																<v-icon size="20" v-else>{{ data.item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-title
																style="text-transform: lowercase !important; color: #808080 !important"
																>{{ data.item.titleName }}
																<span style="font-size: 11px !important">{{ data.item.membersEmail }}</span>
															</v-list-item-title>
														</template>
													</v-checkbox>
												</v-list-item-content>
											</template>
										</template>
									</v-autocomplete>
								</template>
							</v-checkbox>
						</v-list-item>
					</template>
				</v-list>
				<!-- Due date listing -->
				<v-list class="px-3">
					<span class="">Due date</span>
					<template v-for="(item, index) in dueDateList">
						<v-list-item class="px-0" :key="index" style="min-height: 42px !important; height: 42px">
							<!-- <v-list-item-action class="ms-0 me-2 my-0"> -->
							<v-checkbox hide-details v-model="item.checkDuedate">
								<template #label
									><v-avatar class="mr-2" size="30" :color="item.color" v-if="item.icon">
										<v-icon size="20">{{ item.icon }}</v-icon> </v-avatar
									>{{ item.labelTitle }}</template
								>
							</v-checkbox>
							<!-- </v-list-item-action> -->
						</v-list-item>
					</template>
				</v-list>
				<!-- labels listing -->
				<v-list class="px-3">
					<span class="darkgrey--text">Labels</span>
					<template v-for="(item, index) in boardLabels">
						<v-list-item
							class="px-0 pt-4"
							:key="index"
							style="min-height: 34px !important; height: 34px; display: block"
						>
							<v-list-item-content>
								<v-checkbox
									class="members-check mt-1 position-absolute"
									hide-details
									v-model="item.checkLabels"
								>
									<template #label v-if="item.color">
										<v-avatar class="mr-2" size="25" color="secondary" v-if="item.avatar">
											<v-icon size="20">{{ item.avatar }}</v-icon>
										</v-avatar>
										<v-list-item-title
											class="px-2 rounded labels_item"
											style="height: 25px !important; line-height: 25px; opacity: 0.7"
											:style="{ backgroundColor: item.color }"
										>
											{{ item.title }}
										</v-list-item-title>
									</template>
									<template #append v-else>
										<v-autocomplete
											style="background-color: rgb(238, 231, 231); width: 100% !important"
											:items="boardLabels"
											hide-details
											outlined
											placeholder="Select Members"
											v-model="labelsSelect"
											required
											class="mb-2 mt-2 rounded"
										>
											<template v-slot:selection="data">
												<span class="text-capitalize"> {{ data.item.id }} Select Members</span>
											</template>
											<template v-slot:item="data">
												<template>
													<v-list-item-content
														style="min-width: 250px !important"
														v-if="data.item.color && data.item.title != 'No Labels'"
													>
														<v-checkbox
															v-model="data.item.checkLabels"
															class="members-check mx-0 my-0"
															hide-details
														>
															<template #append>
																<v-list-item-title
																	class="px-2 rounded labels_item"
																	style="height: 25px !important; line-height: 25px !important; opacity: 0.7"
																	:style="{ backgroundColor: data.item.color }"
																>
																	{{ data.item.title }}
																</v-list-item-title>
															</template>
														</v-checkbox>
													</v-list-item-content>
													<v-list-item-content style="min-width: 250px !important" v-else></v-list-item-content>
												</template>
											</template>
										</v-autocomplete>
									</template>
								</v-checkbox>
							</v-list-item-content>
						</v-list-item>
					</template>
				</v-list>
				<!-- Activity listing -->
				<v-list class="px-3 pb-5">
					<span class="darkgrey--text">Activity</span>
					<v-radio-group v-model="checkActivity">
						<v-radio
							on-icon="mdi-checkbox-marked"
							off-icon="mdi-checkbox-blank-outline"
							v-for="(item, index) in activityList"
							:key="index"
							:label="item.labelTitle"
							:value="item.labelTitle"
						></v-radio>
					</v-radio-group>
				</v-list>
				<v-list class="px-2 card-member pt-5" style="border-top: 1px solid darkgrey">
					<div>
						<v-autocomplete
							:items="matchingListItem"
							hide-details
							outlined
							placeholder="Matching.."
							v-model="matching_field"
							item-text="title"
							item-value="id"
							required
							class="mb-2 mt-0 rounded"
							style="background-color: rgb(238, 231, 231)"
						>
							<template v-slot:selection="data">
								<span class="text-capitalize"> {{ data.item.title }}</span>
							</template>
							<template v-slot:item="data">
								<v-list-item-content style="min-width: 300px !important">
									<v-list-item-title style="text-transform: capitalize !important; color: #000 !important"
										>{{ data.item.title }}
									</v-list-item-title>
									<v-list-item-subtitle>{{ data.item.subtitle }} </v-list-item-subtitle>
								</v-list-item-content>
							</template>
						</v-autocomplete>
					</div>
				</v-list>
			</div>
		</v-menu>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "MemberCard",
	data() {
		return {
			// :input-value="active"
			filtercard: false,
			// selectedMember: [1],
			keyword_field: "",
			matching_field: "Any match",
			matchingList: [],
			membersSelect: null,
			labelsSelect: "Select Members",
			checkActivity: false,
			membersListItem: [
				{
					id: 1,
					members_Select: false,
					color: "cyan",
					text_Title: "S",
					icon: "",
					titleName: "John",
					membersEmail: "john@gmail.com",
				},
			],

			matchingListItem: [
				{
					id: 1,
					title: "Any match",
					subtitle: "matches all labels and all members",
				},
				{
					id: 2,
					title: "Exact match",
					subtitle: "matches all labels and all members",
				},
			],
			boardMembers: [
				{
					id: 2,
					title: "No Members",
					color: "blue",
					text: "",
					icon: "mdi-account-outline",
					selectedMember: false,
				},
				{
					id: 2,
					title: "Cards assigned to me",
					color: "orange",
					text: "JD",
					icon: "",
					selectedMember: false,
				},
				{
					id: 3,
					title: "",
					color: "",
					text: "",
					icon: "",
					selectedMember: false,
				},
			],
			boardLabels: [
				{
					id: 1,
					color: "white",
					avatar: "mdi-label-outline",
					title: "No Labels",
					checkLabels: false,
				},
				{
					id: 2,
					color: "green",
					title: "On page",
					checkLabels: false,
				},
				{
					id: 3,
					color: "yellow",
					title: "Off page",
					checkLabels: false,
				},
				{
					id: 4,
					color: "orange",
					title: "",
					checkLabels: false,
				},
				{
					id: 5,
					color: "",
					title: "",
					checkLabels: false,
				},
			],
			activityList: [
				{
					id: 1,
					checkActivity: false,
					labelTitle: "Active in the last week",
				},
				{
					id: 2,
					checkActivity: false,
					labelTitle: "Active in the last two weeks",
				},
				{
					id: 3,
					checkActivity: false,
					labelTitle: "Active in the last four weeks",
				},
				{
					id: 4,
					checkActivity: false,
					labelTitle: "Without activity in the last four weeks",
				},
			],
			dueDateList: [
				{
					id: 1,
					checkDuedate: false,
					labelTitle: "No dates",
					icon: "mdi-calendar-outline",
					color: "secondary",
				},
				{
					id: 2,
					checkDuedate: false,
					labelTitle: "Overdue",
					icon: "mdi-clock-outline",
					color: "red",
				},
				{
					id: 3,
					checkDuedate: false,
					labelTitle: "Due in the next day",
					icon: "mdi-clock-outline",
					color: "orange",
				},
				{
					id: 4,
					checkDuedate: false,
					labelTitle: "Due in the next week",
					icon: "mdi-clock-outline",
					color: "secondary",
				},
				{
					id: 5,
					checkDuedate: false,
					labelTitle: "Due in the next month",
					icon: "mdi-clock-outline",
					color: "secondary",
				},
				{
					id: 6,
					checkDuedate: false,
					labelTitle: "Marked as complete",
					icon: "",
					color: "",
				},
				{
					id: 7,
					checkDuedate: false,
					labelTitle: "Not Marked as complete",
					icon: "",
					color: "",
				},
			],
		};
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.members-check .v-input__control {
	width: auto !important;
}
.members-check {
	display: flex;
	align-items: center;
}
.members-check .v-input__slot {
	width: auto !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 12px !important;
}
.labels_item:hover {
	opacity: 1 !important;
}
/* .main-detail > .v-menu__content.theme--light.menuable__content__active {
	position: absolute;
    right: 0 !important;
	left: auto!important;
} */
</style>
